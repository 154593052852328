body {
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #000;
}

img {
  max-width: 100%;
}

.container, .form-container {
  display: flex;
  flex-direction: row;

  -webkit-filter: blur(0);
  filter: blur(0);
  -webkit-transition: -webkit-filter 0.5s cubic-bezier(.215,.61,.355,1);
  transition: -webkit-filter 0.5s cubic-bezier(.215,.61,.355,1);
  transition: filter 0.5s cubic-bezier(.215,.61,.355,1);
  transition: filter 0.5s cubic-bezier(.215,.61,.355,1),-webkit-filter 0.5s cubic-bezier(.215,.61,.355,1);
}

#root {
  height: 100%;
  display: flex;
}

.form-container {
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
  position: fixed;
  right: 0;
  left: 0;
  overflow-x: scroll;
  z-index: 9999;
}

.container {
  padding: 20px;
  padding-top: 100px;
  width: calc(100vw - 40px);
  height: calc(100% - 120px);

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
}

form {
  display: flex;
  flex-grow: 1;

  &:first-child {
    padding-right: 20px;
  }

  &:last-child {
    padding-left: 20px;
  }
}

input[type="text"] {
  box-shadow: 0 0 8px rgba(70,84,80,.2);
  font-size: 1rem;
  height: 42px;
  border: 1px solid #bdbdbd;
  padding: 0 1rem;
  background-color: #fff;
  outline: none;
  flex-grow: 1;
  margin-left: -1px;

  &:focus {
    border-color: #000;
  }
}

input[type="submit"], button, .button {
  box-shadow: 0 0 8px rgba(70,84,80,.3);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  outline: none;
  font-size: 1rem;
  padding-right: 16px;
  padding-left: 16px;
  height: 44px;
  color: #fff;
  background-color: #000;
  border: none;
  -webkit-transition: background-position .3s ease;
  transition: background-position .3s ease;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  align-items: center;
  z-index: 1;

  svg {
    width: 20px;
    height: 20px;
  }
}

input[type="submit"] {
  border-radius: 0;
  font-weight: 700;
  border-right: 1px solid #fff;
}

.random-button {
  border-radius: 0  10px 10px 0;
}

.random-button-center {
  border-radius: 10px;
}

.recto, .verso {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  img {
    max-height: 80vh;
  }

  @media (max-width: 800px) {
    width: 100%;
  }
}


.verso {
  padding-right: 20px;

  @media (max-width: 800px) {
    padding-right: 0;
    padding-bottom: 20px;
  }
}

.recto {
  padding-left: 20px;

  @media (max-width: 800px) {
    padding-left: 0;
  }
}


.button-white {
  box-shadow: 0px 0px 8px rgba(70, 84, 80, 0.3);
  font-size: 18px;
  background: #ffffff;
  color: #000;
  padding: 10px;
  border: 1px solid #000;
  width: fit-content;
  box-shadow: 0px 0px 8px rgba(70, 84, 80, 0.3);
  border-radius: 10px;
  z-index: 999;
  outline: none;
  height: auto;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
  }
}

.utility-button-container {
  display: flex;

  &.left {
    position: fixed;
    bottom: 20px;
    left: 20px;

    .visit-button {
      margin-right: 20px;
    }
  }

  &.right {
    position: fixed;
    bottom: 20px;
    right: 20px;
    flex-direction: row-reverse;

    .visit-button {
      margin-left: 20px;
    }
  }

  &.center {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
}


.block.block--channel {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 315px;
  height: 315px;
  margin-bottom: 3em;
  text-decoration: none;
  color: rgb(75, 61, 103) !important;
  border-color: rgb(207, 203, 214);
      box-sizing: border-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    border-width: 2px;
    border-style: solid;
    border-color: initial;
    border-image: initial;
    border-radius: 0.125em;
    margin: auto;
}

.block.block--text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.block.block--channel.open {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 315px;
  height: 315px;
  margin-bottom: 3em;
  text-decoration: none;
  color: #24AA23 !important;
  border-color: #24AA23;
      box-sizing: border-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    border-width: 2px;
    border-style: solid;
    border-color: initial;
    border-image: initial;
    border-radius: 0.125em;
    margin: auto;
}

.block.block--channel.open a {
  text-decoration: none;
  color: #24AA23;
}

.block.block--channel a {
  text-decoration: none;
  color: #000;
}

.block.block--channel small {
  display: block;
}

.button-refresh {
  border-radius: 10px 0 0 10px;
}

.visit-button {
  text-decoration: none;
  svg {
    padding-right: 6px;
  }

  @media (max-width: 800px) {
    display: none;
  }
}

.info-button-center {
  position: fixed;
  display: inline-block;
  bottom: 20px;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
}

.info {
  display: none;
  z-index: 900;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: hsla(0,0%,98%,.7);

  a {
    color: black;
  }

  .content {
    max-width: 600px;
    padding: 0 20px;
    font-size: 20px;
    line-height: 1.6;

    h2 {
      line-height: 1.2;
      font-weight: 500;
    }
  }
}

.info-open {
  .info {
    display: flex;
  }

  .container, .form-container {
    -webkit-filter: blur(4px);
    filter: blur(4px);
  }
}

.loading {
  position: absolute;
  left: 50%;
  margin-left: -50px;
  top: 50%;
  margin-top: -50px;
}